import React, { useEffect, useCallback, useState } from 'react';
import {
  Tabs,
  Tab,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Tooltip
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { signOut, signInWithRedirect } from '@aws-amplify/auth';

import VodafoneLogo from '../../assets/icons/vodafone-logo.svg';
import LoginIcon from '../../assets/icons/user.svg';
import LogoutIcon from '../../assets/icons/logout.svg';
import StyledHeader from './styles';
import { IAuthState } from '../../interfaces/IAuthState';
import { UserRoles } from '../../services/authListener/index';
import useBroadcastChannel from '../../services/authListener/useBroadcastChannel';

interface IHeaderMenu {
  label: string;
  route: string;
}
interface IHeaderTab {
  label: string;
  route: string;
  menus?: IHeaderMenu[];
}

interface IHeaderProps {
  userState: IAuthState;
  setUserState: (userState: IAuthState) => void;
}

const headerTabs: IHeaderTab[] = [
  {
    label: 'Home',
    route: '/'
  }
];

const orderManagementHeaderTab: IHeaderTab[] = [
  {
    label: 'Order Management',
    route: '/order-management'
  }
];

const inventoryManagementHeaderTab: IHeaderTab = {
  label: 'Inventory Management',
  route: '/inventory-management',
  menus: [
    {
      label: 'Product Catalogue',
      route: '/inventory-management/product-catalogue'
    },
    {
      label: 'SKU Groups',
      route: '/inventory-management/sku-groups'
    }
  ]
};

const reportingHeaderTab: IHeaderTab = {
  label: 'Reporting',
  route: '/reporting',
  menus: [
    {
      label: 'Order Reporting',
      route: '/reporting/order'
    },
    {
      label: 'Returns Reporting',
      route: '/reporting/returns'
    },
    {
      label: 'Stock Alignment Reporting',
      route: '/reporting/stock-alignment'
    },
    {
      label: 'Inventory Reporting',
      route: '/reporting/inventory'
    },
    {
      label: 'Device Enrolment Reporting',
      route: '/reporting/device-enrolment'
    }
  ]
};

const configHeaderTab: IHeaderTab = {
  label: 'Configuration',
  route: '/configuration',
  menus: [
    {
      label: 'Hierarchy Delivery Mapping',
      route: '/configuration/hierarchy-delivery'
    },
    {
      label: 'Origin Segment Mapping',
      route: '/configuration/origin-segment'
    },
    {
      label: 'Return Bag Overrides',
      route: '/configuration/return-bag-overrides'
    },
    {
      label: 'State Change Add-ons',
      route: '/configuration/state-change-addons'
    },
    {
      label: 'Product Present Add-ons',
      route: '/configuration/product-present-addons'
    },
    {
      label: 'Hierarchy Add-ons',
      route: '/configuration/hierarchy-addons'
    },
    {
      label: 'Low Value Delivery Override',
      route: '/configuration/low-value-delivery-override'
    }
  ]
};

const forecastingHeaderTab: IHeaderTab = {
  label: 'Forecasting',
  route: '/forecasting',
  menus: [
    {
      label: 'Retail Replenishment',
      route: '/forecasting/retail-replenishment'
    },
    {
      label: 'Store Configuration',
      route: '/forecasting/store-configuration'
    }
  ]
};

const fullHeaderList: IHeaderTab[] = [
  ...headerTabs,
  ...orderManagementHeaderTab,
  inventoryManagementHeaderTab,
  reportingHeaderTab,
  configHeaderTab,
  forecastingHeaderTab
];

const Header: React.FC<IHeaderProps> = ({ userState, setUserState }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { sendMessage }: any = useBroadcastChannel('auth_channel');
  const [tabIndex, setTabIndex] = useState(0);
  let allHeaderTabs =
    userState.authState === 'signedIn'
      ? [
          ...headerTabs,
          ...orderManagementHeaderTab,
          inventoryManagementHeaderTab
        ]
      : headerTabs;
  const [menuParentElem, setMenuParentElem] = useState(null);
  const [submenus, setSubmenus] = useState<IHeaderMenu[]>([]);
  const [securityGroups, setSecurityGroups] = useState<string>('');

  useEffect(() => {
    const path = location.pathname;
    const userPermissions = userState.permissions;

    if (
      userState.authState === 'signedOut' &&
      (orderManagementHeaderTab[0].route === path ||
        inventoryManagementHeaderTab.menus?.some((e) => e.route === path) ||
        reportingHeaderTab.menus?.some((e) => e.route === path) ||
        configHeaderTab.menus?.some((e) => e.route === path) ||
        forecastingHeaderTab.menus?.some((e) => e.route === path))
    ) {
      navigate('/page-forbidden');
    }

    if (
      (reportingHeaderTab.menus?.some((e) => e.route === path) &&
        userPermissions?.viewOrderReports === false &&
        userPermissions?.viewDeviceEnrolmentReport === false) ||
      (configHeaderTab.menus?.some((e) => e.route === path) &&
        userPermissions?.viewConfigInfo === false) ||
      (path === '/inventory-management/sku-groups' &&
        userPermissions?.viewSkuGroups === false) ||
      (forecastingHeaderTab.menus?.some((e) => e.route === path) &&
        userPermissions?.viewForecastingData === false)
    ) {
      navigate('/page-forbidden');
    }
  }, [location.pathname, userState]);

  if (
    userState.permissions?.viewDeviceEnrolmentReport === true &&
    userState.permissions?.viewOrderReports === false
  ) {
    reportingHeaderTab.menus = [
      {
        label: 'Device Enrolment Reporting',
        route: '/reporting/device-enrolment'
      }
    ];
  } else if (
    userState.permissions?.viewDeviceEnrolmentReport === false &&
    userState.permissions?.viewOrderReports === true
  ) {
    reportingHeaderTab.menus = [
      {
        label: 'Order Reporting',
        route: '/reporting/order'
      },
      {
        label: 'Returns Reporting',
        route: '/reporting/returns'
      },
      {
        label: 'Stock Alignment Reporting',
        route: '/reporting/stock-alignment'
      },
      {
        label: 'Inventory Reporting',
        route: '/reporting/inventory'
      }
    ];
  }

  if (userState.permissions?.viewSkuGroups === false) {
    inventoryManagementHeaderTab.menus = [
      {
        label: 'Product Catalogue',
        route: '/inventory-management/product-catalogue'
      }
    ];
  }

  if (
    userState.permissions?.viewOrderReports === true ||
    userState.permissions?.viewDeviceEnrolmentReport === true
  )
    allHeaderTabs = [...allHeaderTabs, reportingHeaderTab];

  if (userState.permissions?.viewConfigInfo === true)
    allHeaderTabs = [...allHeaderTabs, configHeaderTab];

  if (userState.permissions?.viewForecastingData === true)
    allHeaderTabs = [...allHeaderTabs, forecastingHeaderTab];

  useEffect(() => {
    const currentTabIndex: number = allHeaderTabs.findIndex(
      (tab: IHeaderTab) =>
        tab.route === location.pathname ||
        location.pathname.startsWith(`${tab.route}/`)
    );
    setTabIndex(currentTabIndex);
  }, [location]);

  const handleConfigMenuClick = useCallback(
    (route: string, allTabs: IHeaderTab[]) => () => {
      setMenuParentElem(null);
      setTabIndex(
        allTabs.findIndex((tab: IHeaderTab) =>
          route.startsWith(`${tab.route}/`)
        )
      );
      navigate(route);
    },
    [location]
  );

  const handleTabChange = useCallback(
    (newTabIndex: number, route: string) => (event: any) => {
      const selTab = fullHeaderList.find(
        (tab: IHeaderTab) => tab.route === route
      );

      if (selTab && selTab.menus) {
        setSubmenus(selTab.menus);
        setMenuParentElem(event.target);
      } else {
        setTabIndex(newTabIndex);
        navigate(route);
      }
    },
    [location]
  );

  const handleSignOut = async () => {
    await signOut();
    setUserState({ authState: 'signedOut', user: undefined });
    sendMessage({ type: 'signOut' });
  };

  useEffect(() => {
    if (userState?.user?.idToken?.payload?.profile) {
      const profile = userState?.user?.idToken?.payload?.profile;
      const processedGroups: string[] = profile
        .slice(1, -1)
        .split(',')
        .map((item: string) => item.trim());

      const cavendishSecurityGroups = processedGroups
        .filter((value: any) => Object.values(UserRoles).includes(value))
        .join(', ');

      setSecurityGroups(cavendishSecurityGroups);
    }
  }, [userState]);

  return (
    <StyledHeader>
      <div className='header__primary-menu' />
      <Container fixed className='header__secondary-menu'>
        <div className='header__secondary-menu__navigation'>
          <img src={VodafoneLogo} alt='Vodafone Logo' />
          <Tabs value={tabIndex}>
            {allHeaderTabs.map((tab, index) => (
              <Tab
                className='header__secondary-menu__navigation__tab'
                tabIndex={0}
                key={tab.label}
                label={tab.label}
                onClick={handleTabChange(index, tab.route)}
              />
            ))}
          </Tabs>
        </div>
        <Menu
          id='config-menu'
          keepMounted
          open={Boolean(menuParentElem)}
          onClose={() => setMenuParentElem(null)}
          anchorEl={menuParentElem}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          {submenus.map((menu) => (
            <MenuItem
              key={menu.label}
              style={{ fontFamily: 'Vodafone Lt' }}
              onClick={handleConfigMenuClick(menu.route, allHeaderTabs)}
            >
              {menu.label}
            </MenuItem>
          ))}
        </Menu>
        {userState.authState === 'signedIn' ? (
          <Tooltip
            title={
              <>
                <p>User: {userState?.user?.idToken?.payload?.name}</p>
                <p>Group: {securityGroups}</p>
              </>
            }
            arrow
          >
            <IconButton
              tabIndex={0}
              aria-label='Logout'
              onClick={handleSignOut}
            >
              <img src={LogoutIcon} alt='Logout' />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton
            tabIndex={0}
            aria-label='Login'
            onClick={() => signInWithRedirect({ provider: { custom: 'IdP' } })}
          >
            <img src={LoginIcon} alt='Login' />
          </IconButton>
        )}
      </Container>
    </StyledHeader>
  );
};

export default Header;
